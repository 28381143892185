<template>
    <div>
        <div v-if="!dataLoaded && !loading">
            <form @submit.prevent="loadTicketData">
                <div class="input-group">
                    <label for="api_key">API Key:</label>
                    <input type="text" id="api_key" v-model="apiKey" placeholder="API Key eingeben">
                </div>
                <div class="input-group">
                    <label for="product_id">Produkt ID:</label>
                    <input type="text" id="product_id" v-model="productId" placeholder="Produkt ID eingeben">
                </div>
                <button type="submit" class="submit-button">Daten laden</button>
            </form>
            <div v-if="error" class="error-message">
                <p>{{ error }}</p>
            </div>
        </div>
        <div v-if="loading" class="loading-message">
            <h3>Ticket Scanner versucht Daten vom Server zu laden...</h3>
        </div>
        <div v-if="dataLoaded" class="ticket-scanner">
            <div class="ticket-status-container" :class="{ 'valid': currentTicketData }">
                <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
            </div>
            <Transition name="fade" mode="out-in">
                <div v-if="currentTicketData" :key="currentTicketData" class="ticket-detail">
                    <h2>{{ currentTicketData['First Name'] }} {{ currentTicketData['Last Name'] }}</h2>
                    <h3>Anzahl Sitzplätze: {{ currentTicketData['Quantity'] }}</h3>
                    <span>{{ currentTicketData['Email'] }}</span>
                </div>
                <div v-else class="ticket-detail invalid">
                    <h2>Ungültig oder nicht Gescannt</h2>
                    <p><b>Bitte scanne ein gültiges Ticket.</b></p>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import axios from 'axios'

export default {
    name: 'TicketScanner',
    data() {
        return {
            apiKey: "",
            productId: "",
            ticketId: "",
            currentTicketData: null,
            ticketData: [],
            dataLoaded: false,
            loading: false,
            error: null
        }
    },
    components: {
        StreamBarcodeReader
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.apiKey = urlParams.get('api_key') || '';
        this.productId = urlParams.get('product_id') || '';

        if (this.apiKey && this.productId) {
            this.loadTicketData();
        }
    },
    methods: {
        findTicket(ticketID) {
            let results = this.ticketData.filter(obj => obj['OrderID'].toString() === ticketID);
            this.currentTicketData = results.length > 0 ? results[0] : null;
        },

        onDecode(text) {
            this.currentTicketData = null;
            this.findTicket(text);
        },

        onLoaded() {
            console.log(`Ready to start scanning barcodes`);
        },

        async loadTicketData() {
            this.loading = true;
            try {
                const response = await axios.get(`https://www.autisten.info/wp-json/myapi/v1/ticketdata/?api_key=${this.apiKey}&product_id=${this.productId}`, {
                    headers: {'Content-Type': 'application/json'}
                });
                this.ticketData = response.data.Orders;
                this.dataLoaded = true;
                this.loading = false;
            } catch (error) {
                console.error("Failed to fetch tickets:", error);
                this.error = "Fehler beim Laden der Tickets: " + error.message;
                this.loading = false;
                this.dataLoaded = false;
            }
        },
    }
}
</script>

<style scoped>
.input-group label {
    display: block;
}
.input-group input {
    width: 100%;
    margin-bottom: 10px;
}
.submit-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}
.error-message {
    color: red;
}
.loading-message h3 {
    color: #ffa500;  /* Orange color for the loading message */
}
    .ticket-scanner {

    }
    .scanner-container {
        aspect-ratio: 1/1;
        min-height: unset;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;

    }
    .ticket-status-container{
        border: solid 10px rgb(219, 150, 0);
        border-radius: 25px;
        aspect-ratio: 1/1;
        padding: 10px;
        width: 60vw;
        margin-left: auto;
        margin-right: auto;
    }
    .valid{
        border: solid 10px rgb(18, 219, 0) !important;
    }



    .ticket-detail {
        background: rgb(34,195,120);
        background: linear-gradient(0deg, rgba(34,195,120,1) 0%, rgba(45,253,181,1) 100%);
        border-radius: 20px;
        padding: 10px;
        margin-top: 30px;
        color: #fff;
    }
    .ticket-detail.invalid {
        background: rgb(195,34,34);
        background: linear-gradient(0deg, rgb(195, 85, 34) 0%, rgb(253, 121, 45) 100%);
    }


    /* Animation */
    .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
  </style>
  