<template>
  <TicketScanner></TicketScanner>
</template>

<script>
import TicketScanner from './components/TicketScanner.vue';


export default {
  name: 'App',
  components: {
    TicketScanner
}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f3f3f3;
  margin-top: 60px;

}
body{
  background-color: #03a9f4;
}
</style>
